var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card',[_c('v-card-title',[_vm._v(" Orders "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"change":function($event){return _vm.searchOrders()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"single-expand":true,"headers":_vm.datatable.headers,"items":_vm.datatable.desserts,"server-items-length":_vm.datatable.total,"loading":_vm.datatable.isLoading,"options":_vm.options,"footer-props":{
              showFirstLastPage: true,
              itemsPerPageOptions: [5, 10, 20, 50, 100]
            },"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customer_name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.shipping_address && item.shipping_address.full_name)+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.getStatusColor(item.status),"text-color":"white"}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.profit",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.profit) + "%")+" ")]}},{key:"item.paid_date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.paid_date))+" ")]}},{key:"item.order_date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.order_date))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"small":"","title":"Create or update invoice"},on:{"click":function($event){return _vm.syncOrderToEdara(item)}}},[_vm._v(" mdi-sync ")]),_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"small":"","title":"Create new invoice"},on:{"click":function($event){return _vm.syncOrderToEdara(item, true)}}},[_vm._v(" mdi-sync-alert ")]),_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"small":"","title":"Get order from store"},on:{"click":function($event){return _vm.pullFromPCDStore(item)}}},[_vm._v(" mdi-reload ")]),_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"small":"","title":"Delete order from table"},on:{"click":function($event){return _vm.deleteOrder(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"small":"","title":"Mark order as sent"},on:{"click":function($event){return _vm.markOrderAsSent(item)}}},[_vm._v(" mdi-check ")])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(item.error)+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.lines_datatable.headers,"items":item.line_items,"item-key":"id"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.price).toFixed(2))+" ")]}}],null,true)})],1)],1)],1)]}}])})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }